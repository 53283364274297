import { CapacitorHttp } from "@capacitor/core";
import { getRequestApiUrl, getApiRequestHeaders, getPreBootstrapConfig } from "../util/ApiHelper";
import { getApiSession, setApiSession } from "../util/ApiOptionsHelper";

//TODO: move to a common place later
declare global {
  var globalInitialized: boolean;
  var abpLoginInProgress: boolean;
  var abpRevalidateSession: boolean;
  var isLoginSuccessful: boolean;
  var fromReturn: boolean;
  var customLogOutUrl : string;
}

//initialize global values
if (!globalThis.globalInitialized) {
  globalThis.abpLoginInProgress = false;
  globalThis.abpRevalidateSession = false;
  globalThis.globalInitialized = true;
  globalThis.isLoginSuccessful = false;
  globalThis.fromReturn = false;
  globalThis.customLogOutUrl = '';
}

export const initializeAbpSession = async (endActiveSession: boolean = false) => {
  if (!globalThis.abpLoginInProgress) {
    globalThis.abpLoginInProgress = true;
    const sessionValid = await abpLogin(endActiveSession);
    globalThis.abpLoginInProgress = false;
    return sessionValid;
  }
  return { validLogin: true, message: '' }
};

export const abpLogout = async () => {
  const response = await Promise.all([getApiRequestHeaders(), getRequestApiUrl("abp/logout/")]).then(async (values) => {
    const headers = values[0];
    const url = values[1];

    const options = {
      url: url,
      headers: headers,
    };
    return await CapacitorHttp.post(options);
  });
  return response;
};

export const getCustomLogOutUrl = async () => {
  const response = await Promise.all([getApiRequestHeaders(), getRequestApiUrl("LegalUnits/GetLogOutUrl")]).then(async (values) => {
    const headers = values[0];
    const url = values[1];

    const options = {
      url: url,
      headers: headers,
    };
    return await CapacitorHttp.get(options).then((response) => {
      //if authorization fails or some error occurs, return and don't set configuredLogOutUrl
      if(response.status !== 200)
      {
        return;
      }
      globalThis.customLogOutUrl = response.data;
      return;
    }).catch((error) => {
      return;
    });
  });
  return response;
};

const abpLogin = async (endActiveSession: boolean) => {
  const response = await Promise.all([getApiRequestHeaders(), getApiSession(), getRequestApiUrl("abp/login/"), getPreBootstrapConfig()]).then(async (values) => {
    const headers = values[0];
    const apiSession = values[1];
    const url = values[2];
    const preBootstrapConfig = values[3];

    const options = {
      url: url,
      headers: headers,
      data: {
        "extraLoginInfo":
          `domainHint=${preBootstrapConfig.ABP_DOMAIN_HINT}&database=${apiSession.DatabaseName}&plant=${apiSession.PlantCode}`,
          "endActiveSession": endActiveSession
      }
    }
    const resp = CapacitorHttp.post(options).then(async (response) => {
      if (response.status === 200 && !!response.headers["x-tw-licsession-id"]) {
        apiSession.LicenseSessionId = response.headers["x-tw-licsession-id"];
        apiSession.LicenseIssuedAt = new Date().valueOf();
        apiSession.LicenseExpiration = 0; //do not use
        await setApiSession(apiSession);
        return { validLogin: true, message: '' }
      } 
      else if (response.data.resultCode && response.data.resultCode === 3) {
        return { validLogin: false, message: response.data.errorMessage, resultCode: response.data.resultCode }
      }
      else {
        return { validLogin: false, message: response.data === "" ? "Invalid URL" : response.data.errorMessage }
      }
    }).catch(async (error) => {
      return { validLogin: false, message: "Invalid URL" }
    });
    return resp;
  });
  globalThis.isLoginSuccessful = true;
  return response;
};
